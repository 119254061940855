<template>
  <div
    id="ContentPlaceHolder1_topPageDiv"
    class="d-md-flex justify-content-between mb-4"
    style="margin-top: 20px"
  >
    <p class="mb-2 mb-md-0"></p>
    <nav aria-label="page navigation">
      <ul class="pagination pagination-sm mb-0">
        <li class="page-item" data-toggle="tooltip" title="">
          <a
            class="page-link"
            href="javascript:void(0)"
            v-on:click="exec(-1)"
            aria-hidden="True"
          >
            <i class="fa fa-chevron-left small"></i>

            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li Class="page-item disabled">
          <span Class="page-link text-nowrap"
            >Page
            <strong class="font-weight-medium">{{ currentPage }}</strong>
            of
            <strong class="font-weight-medium">{{
              parseInt(total / pageSize) + 1
            }}</strong></span
          >
        </li>
        <li class="page-item" data-toggle="tooltip" title="">
          <a
            class="page-link"
            href="javascript:void(0)"
            v-on:click="exec(1)"
            aria-hidden="True"
          >
            <i class="fa fa-chevron-right small"></i>
            <span class="sr-only">Next</span></a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    execPage: null,
    total: 0,
    currentPage: 0,
    pageSize: 0,
  },
  methods: {
    exec(page) {
      if (this.execPage) this.execPage(page);
    },
  },
};
</script>
