<template>
  <main id="content" role="main" v-loading="infoLoading">
    <div class="container py-3">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div class="mb-3 mb-lg-0">
          <h1 class="h4 mb-0">
            <!-- <img id="icon" class="u-xs-avatar rounded mt-n1 mr-1" src="#" alt="" /> -->
            <!-- {{ tokenInfo.symbol ? "Token " : "Contract " }} -->
            {{ tokenInfo.ERC != 0 ? "Token" : "Contract " }}
            <span
              id="mainaddress"
              class="text-size-address text-secondary text-break mr-1"
              data-placement="top"
              >{{
                tokenInfo.ERC != 0
                  ? tokenInfo.name + "(" + tokenInfo.symbol + ")"
                  : currentContract
              }}
            </span>
            <img
              width="25"
              v-if="tokenInfo.logo"
              :src="tokenInfo.logo"
              alt=""
              style="border-radius: 50%"
            />
          </h1>
          <div class="mt-1"></div>
        </div>
        <div class="d-flex flex-wrap"></div>
      </div>
    </div>
    <div id="overlayMain"></div>
    <div class="container"></div>
    <div id="ContentPlaceHolder1_divSummary" class="container space-bottom-2">
      <div class="row mb-4">
        <div class="col-md-6 mb-3 mb-md-0">
          <div class="card h-100">
            <div
              class="
                card-header
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <h2 class="card-header-title">
                Overview
                <template v-if="tokenInfo.ERCType">
                  <span class="test-tip" style="font-size: 0.1rem">
                    [{{ tokenInfo.ERCType }}]</span
                  >
                </template>
              </h2>
              <div></div>
            </div>

            <!-- OverValue  -->
            <div class="card-body">
              <template v-if="tokenInfo.symbol">
                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Symbol:</div>
                  <div class="col-md-8">
                    <span id="Public_Private_Tag"
                      >{{ tokenInfo.symbol }}
                      <!-- <a data-toggle='tooltip' title=""
                                                href="../login.html">, login to update</a> -->
                    </span>
                    <span id="Public_Private_Tag_edit_button"></span>
                  </div>
                </div>
                <hr class="hr-space" />

                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">
                    {{ getName("Token", "Total Supply") }}:
                  </div>
                  <div class="col-md-8">
                    {{ tokenInfo.totalsupply }} {{ tokenInfo.symbol }}
                  </div>
                </div>

                <hr class="hr-space" />

                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Transfers:</div>
                  <div class="col-md-8">
                    <span class="small" style="position: relative; top: -1px">{{
                      tokenInfo.txCount
                    }}</span>
                  </div>
                </div>
                <hr class="hr-space" />

                <div id="ContentPlaceHolder1_tokenbalance">
                  <div class="row align-items-center">
                    <div class="col-md-4 mb-1 mb-md-0">
                      {{ getName("Token", "Holders") }}:
                    </div>
                    <div class="col-md-8 d-flex justify-content-between">
                      {{ tokenInfo.holders }} addresses
                    </div>
                  </div>
                </div>
                <hr class="hr-space" />
              </template>
              <div>
                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Balance:</div>
                  <div class="col-md-8 d-flex justify-content-between">
                    {{ tokenInfo.balance }} {{ tokenInfo.coinSymbol }}
                  </div>
                </div>
              </div>
              <div id="token-balance" style="display: none">
                <hr class="hr-space" />
                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Token:</div>
                  <div class="col-md-8">
                    <a
                      class="u-header__nav-link-toggle"
                      href="javascript:void(0)"
                      id="a-balance"
                    >
                      <span v-if="tokenBalance.length > 0">
                        {{ tokenBalance[0].value }}
                        {{ tokenBalance[0].symbol }}
                        {{
                          tokenBalance[0].memo
                            ? "[" + tokenBalance[0].memo + "]"
                            : ""
                        }}
                      </span>
                    </a>
                    <div
                      id="token_balance_a"
                      class="card"
                      style="
                        min-width: 230px;
                        display: none;
                        position: absolute;
                        z-index: 999;
                        left: -10px;
                        top: 30px;
                      "
                    >
                      <ul id="ul_balance">
                        <template
                          v-for="item in tokenBalance"
                          :key="item.address"
                        >
                          <li>
                            <a
                              href="#"
                              @click.prevent="showContract(item.address)"
                              class="nav-link u-header__sub-menu-nav-link"
                            >
                              {{ item.value }} {{ item.symbol }}
                              {{ item.memo ? "[" + item.memo + "]" : "" }}
                            </a>
                          </li>
                          <li class="sep"></li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="ContentPlaceHolder1_cardright" class="col-md-6">
          <div class="card h-100">
            <div
              class="
                card-header
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <div id="ContentPlaceHolder1_td_misc" class="d-flex">
                <h2 class="card-header-title mr-1">
                  More Info
                  <template v-if="tokenInfo.memo">
                    <span class="test-tip" style="font-size: 0.1rem">
                      [{{ tokenInfo.memo }}]</span
                    >
                  </template>
                </h2>
              </div>
              <div id="ContentPlaceHolder1_moreoptionsdiv" class="d-flex">
                <div class="ml-auto mr-1">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="handleAction()"
                    style="display: none"
                    >Apply {{ tokenInfo.symbol ? "Token" : "Contract" }} >></a
                  >
                </div>
              </div>
            </div>

            <div class="card-body">
              <template v-if="tokenInfo.symbol">
                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Contract:</div>
                  <div class="col-md-8">
                    <span id="Public_Private_Tag"
                      >{{ parseLongText(currentContract, 10, 10) }}
                    </span>
                    <span id="spanContract" style="display: none">
                      {{ currentContract }}
                    </span>
                    <a
                      class="js-clipboard text-muted"
                      href="javascript:;"
                      data-toggle="tooltip"
                      title=""
                      style="margin-left: 0.3rem"
                      data-content-target="#spanContract"
                      data-class-change-target="#spanContractLinkIcon"
                      data-success-text="Copied"
                      data-default-class="far fa-copy"
                      data-success-class="far fa-check-circle"
                    >
                      <span
                        id="spanContractLinkIcon"
                        class="far fa-copy"
                      ></span>
                    </a>
                  </div>
                </div>
                <hr class="hr-space" />

                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Decimals:</div>
                  <div class="col-md-8">
                    <span id="Public_Private_Tag"
                      >{{ tokenInfo.decimal }}
                    </span>
                    <span id="Public_Private_Tag_edit_button"></span>
                  </div>
                </div>
                <hr class="hr-space" />

                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">OfficialSite:</div>
                  <div class="col-md-8">
                    <span id="Public_Private_Tag">
                      <a
                        data-toggle="tooltip"
                        title=""
                        :href="getLinkUrl(tokenInfo.officialsite)"
                        >{{ tokenInfo.officialsite }}</a
                      >
                    </span>
                    <span id="Public_Private_Tag_edit_button"></span>
                  </div>
                </div>
                <hr class="hr-space" />

                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">SocialProfiles:</div>
                  <div class="col-md-8">
                    <template
                      v-for="item in tokenInfo.socialprofiles"
                      :key="item"
                    >
                      <span
                        id="Public_Private_Tag"
                        style="
                          display: block;
                          padding-top: 0.25rem;
                          padding-bottom: 0.25rem;
                        "
                      >
                        <a
                          data-toggle="tooltip"
                          title=""
                          v-if="item.length > 0"
                          :href="getLinkUrl(item)"
                          >{{ item }}
                        </a>
                      </span>
                      <span id="Public_Private_Tag_edit_button"></span>
                    </template>
                  </div>
                </div>
                <hr class="hr-space" />
              </template>

              <div v-if="tokenInfo.ownerInfo">
                <div class="row align-items-center">
                  <div class="col-md-4 mb-1 mb-md-0">Contract Creator:</div>
                  <div class="col-md-8 d-flex justify-content-between">
                    <span>
                      <ItemAddress
                        :currentAddress="currentContract"
                        :address="tokenInfo.owner"
                        :info="tokenInfo.ownerInfo"
                      />
                      at txn
                      <el-tooltip
                        class="item"
                        effect="dark"
                        style="margin-end: 0.2rem"
                        :content="tokenInfo.creationTransaction"
                        placement="top"
                      >
                        <a
                          href="javascript:void(0)"
                          @click="toHash(tokenInfo.creationTransaction)"
                        >
                          {{ parseLongText(tokenInfo.creationTransaction) }}
                        </a>
                      </el-tooltip>
                    </span>
                  </div>
                </div>
              </div>
              <span v-else> --- </span>
              <hr class="hr-space" />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div
          class="
            card-header
            d-flex
            justify-content-between
            align-items-center
            p-0
          "
        >
          <ul
            class="nav nav-custom nav-borderless nav_tabs1"
            id="nav_tabs"
            role="tablist"
          >
            <li class="nav-item" v-if="tokenInfo.ERC != 0">
              <a
                class="nav-link"
                href="#transactions"
                id="transactions"
                data-title="Primary Transactions"
                data-toggle="tab"
                v-on:click="changeTab(2)"
              >
                Transfers</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#trans"
                id="transTab"
                data-title="Primary Transactions"
                data-toggle="tab"
                v-on:click="changeTab(1)"
              >
                Transaction</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#internal"
                data-title="Primary Transactions"
                data-toggle="tab"
                v-on:click="changeTab(6)"
              >
                Internal Txns</a
              >
            </li>
            <li class="nav-item" v-if="tokenInfo.ERC == 721">
              <a
                class="nav-link"
                href="#inventory"
                id="inventory"
                data-title="Primary Transactions"
                data-toggle="tab"
                v-on:click="changeTab(5)"
              >
                Inventory</a
              >
            </li>
            <li class="nav-item" v-if="tokenInfo.ERC != 0">
              <a
                class="nav-link"
                href="#holders"
                data-toggle="tab"
                v-on:click="changeTab(3)"
              >
                Holders
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#contract"
                data-toggle="tab"
                v-on:click="changeTab(4)"
              >
                Contract
              </a>
            </li>
          </ul>
        </div>
        <!--token transaction -->
        <div class="card-body" id="card-body-transaction" style="display: none">
          <div class="tab-content">
            <input
              type="hidden"
              id="hdnErc20"
              value=""
              onchange="setGlobalValue(this)"
            />
            <div
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>

              <!--  content -->
              <div
                class="table-responsive mb-2 mb-md-0"
                v-loading="txns.loading"
              >
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Txn Hash</th>
                      <th scope="col">Block</th>
                      <th scope="col">
                        Method
                        <el-tooltip class="item" effect="dark" placement="top">
                          <i class="fal fa-info-circle"></i>
                          <template #content>
                            <div style="width: 200px">
                              <span style="font-size: 10px">
                                Function executed based on decoded input data.
                                For unidentified functions, method ID is
                                displayed instead.
                              </span>
                            </div>
                          </template>
                        </el-tooltip>
                      </th>
                      <th scope="col">
                        <a
                          href="javascript:void(0)"
                          @click="txns.isAge = !txns.isAge"
                          >{{ txns.isAge ? "Age" : "Time" }}</a
                        >
                      </th>
                      <th scope="col">From</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">To</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in txns.data" :key="item._id">
                      <td>
                        <ItemHash :hash="item.hash" />
                      </td>
                      <td>
                        <a
                          href="#"
                          v-on:click.prevent="showBlock(item.blockNumber)"
                          >{{ item.blockNumber }}</a
                        >
                      </td>
                      <td>
                        <span
                          style="min-width: 68px"
                          class="
                            u-label u-label--xs u-label--info
                            rounded
                            text-dark text-center
                          "
                          >{{ item.method }}</span
                        >
                      </td>
                      <td style="" class="showAge">
                        <TimeAge
                          :timestamp="item.timestamp"
                          :isAge="txns.isAge"
                        />
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentContract"
                          :address="item.from"
                          :info="item.fromInfo"
                        />
                      </td>
                      <td>
                        <span
                          v-if="item.from != item.to"
                          class="
                            btn btn-xs btn-icon btn-soft-success
                            rounded-circle
                          "
                          ><i
                            class="fas fa-long-arrow-alt-right btn-icon__inner"
                          ></i
                        ></span>

                        <span
                          v-else
                          class="
                            u-label u-label--xs u-label--secondary
                            color-strong
                            text-uppercase text-center
                            w-100
                            rounded
                            text-nowrap
                          "
                          >SELF</span
                        >
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentContract"
                          :address="item.to"
                          :info="item.toInfo"
                        />
                      </td>
                      <td>
                        {{ formatNumber(item.value, 4) }} {{ item.symbol }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="txns.data.length" />
              </div>
              <!--  -->
              <Page
                :execPage="execTxsPage"
                :total="txns.count"
                :currentPage="txns.page"
                :pageSize="txns.pageNum"
              />
            </div>
          </div>
        </div>
        <!-- transsaction -->
        <div class="card-body" id="card-body" style="display: none">
          <div class="tab-content">
            <input
              type="hidden"
              id="hdnErc20"
              value=""
              onchange="setGlobalValue(this)"
            />
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>

              <!--  content -->
              <div
                class="table-responsive mb-2 mb-md-0"
                v-loading="transaction"
              >
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Txn Hash</th>
                      <th scope="col">Block</th>
                      <th scope="col">
                        <a
                          href="javascript:void(0)"
                          @click="isTransacAge = !isTransacAge"
                          >{{ isTransacAge ? "Age" : "Time" }}</a
                        >
                      </th>
                      <th scope="col">From</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">To</th>
                      <th scope="col">
                        {{
                          tokenInfo && tokenInfo.ERC == 721
                            ? "TokenId"
                            : "Value"
                        }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in TransactionList" :key="item._id">
                      <td>
                        <ItemHash :hash="item.hash" />
                      </td>
                      <td>
                        <a
                          href="#"
                          v-on:click.prevent="showBlock(item.blockNumber)"
                          >{{ item.blockNumber }}</a
                        >
                      </td>
                      <td style="" class="showAge">
                        <TimeAge
                          :timestamp="item.timestamp"
                          :isAge="isTransacAge"
                        />
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentContract"
                          :address="item.from"
                          :info="item.fromInfo"
                        />
                      </td>
                      <td>
                        <span
                          v-if="item.from != item.to"
                          class="
                            btn btn-xs btn-icon btn-soft-success
                            rounded-circle
                          "
                          ><i
                            class="fas fa-long-arrow-alt-right btn-icon__inner"
                          ></i
                        ></span>

                        <span
                          v-else
                          class="
                            u-label u-label--xs u-label--secondary
                            color-strong
                            text-uppercase text-center
                            w-100
                            rounded
                            text-nowrap
                          "
                          >SELF</span
                        >
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentContract"
                          :address="item.to"
                          :info="item.toInfo"
                        />
                      </td>
                      <td>
                        {{
                          item.ERC == 721
                            ? item.value
                            : formatNumber(item.value, 4) + " " + item.symbol
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="TransactionList.length" />
              </div>
              <!--  -->
              <Page
                :execPage="execTransfersPage"
                :total="total"
                :currentPage="currentPage"
                :pageSize="pageNum"
              />
            </div>
          </div>
        </div>
        <div class="card-body" id="card-body1" style="display: none">
          <div class="tab-content">
            <input
              type="hidden"
              id="hdnErc20"
              value=""
              onchange="setGlobalValue(this)"
            />
            <div
              class="tab-pane fade show active"
              id="holders"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>
              <div class="table-responsive mb-2 mb-md-0" v-loading="holders">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col" width="20">Address</th>
                      <th scope="col" width="20">Balance</th>
                      <th scope="col" width="20">Percent(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in HolderList" :key="item._id">
                      <td>
                        <a
                          class="myFnExpandBox_searchVal text-truncate"
                          href="#"
                          @click.prevent="toAddress(item.address)"
                          >{{ item.memo ? item.memo : item.address }}</a
                        >
                      </td>
                      <td style="" class="showAge">
                        <span
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                        >
                          {{ formatNumber(item.balance, 4) }}</span
                        >
                      </td>
                      <td>{{ formatNumber(item.percent, 2) }}</td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="HolderList.length" />
              </div>

              <Page
                :execPage="execHolderPage"
                :total="totalHolder"
                :currentPage="currentPageHolder"
                :pageSize="pageNum"
              />
            </div>
          </div>
        </div>
        <!-- Inventory -->
        <div class="card-body" id="card-body-inventory" style="display: none">
          <div class="tab-content">
            <input
              type="hidden"
              id="hdnErc20"
              value=""
              onchange="setGlobalValue(this)"
            />
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>
              <div
                class="table-responsive mb-2 mb-md-0"
                v-loading="transaction"
              >
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col" width="80">Token Id</th>
                      <th scope="col">Owner</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in erc721TokenIds.data" :key="item._id">
                      <td>
                        {{ item.value }}
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentContract"
                          :address="item.owner"
                          :info="item.ownerInfo"
                          :longAddress="true"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="erc721TokenIds.data.length" />
              </div>
              <Page
                :execPage="execERC721TokenIds"
                :total="erc721TokenIds.count"
                :currentPage="erc721TokenIds.page"
                :pageSize="erc721TokenIds.pageNum"
              />
            </div>
          </div>
        </div>
        <!-- code -->
        <div class="card-body" id="card-body-contract" style="display: none">
          <template v-if="tokenInfo.code">
            <el-radio-group v-model="contract" size="small">
              <el-radio-button label="Code"></el-radio-button>
              <el-radio-button label="Read Contract"></el-radio-button>
              <el-radio-button label="Write Contract"></el-radio-button>
            </el-radio-group>

            <!-- code -->
            <div
              id="contract-code"
              style="margin-top: 1rem"
              :style="{ display: contract == 'Code' ? 'block' : 'none' }"
            >
              <div class="col-md-12">
                <h3 class="h6 text-dark font-weight-bold">
                  <i class="fa fa-check-circle text-success"></i>
                  <strong style="margin-left: 0.2rem"
                    >Contract Source Code Verified
                    <span class="font-weight-normal text-secondary"
                      >(Exact Match)</span
                    ></strong
                  >
                </h3>
              </div>
              <div
                class="card-body overflow-hidden"
                style="position: relative; margin-top: -0.5rem"
              >
                <div class="row mx-gutters-md-1 align-items-center">
                  <!-- item 1 -->
                  <div class="col-md-6">
                    <div
                      class="media align-items-center"
                      style="margin-top: 5px"
                    >
                      <span class="text-size-small"> Contract Name: </span>

                      <span
                        class="text-size-small"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        style="font-size: 1rem; margin-left: 0.3rem"
                        ><b>
                          {{ tokenInfo.contractName }}
                        </b>
                      </span>
                    </div>

                    <hr />
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="text-size-small"> Compiler Version: </span>
                        <span
                          class="text-size-small"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                          style="font-size: 1rem; margin-left: 0.3rem"
                          ><b>
                            {{ tokenInfo.compilerVersion }}
                          </b>
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- item 2 -->
                  <div class="col-md-6">
                    <hr class="hidden" />
                    <div
                      class="media align-items-center"
                      style="margin-top: 5px"
                    >
                      <span class="text-size-small">
                        Optimization Enabled:
                      </span>
                      <span
                        class="text-size-small"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        style="font-size: 1rem; margin-left: 0.3rem"
                        ><b>
                          {{ tokenInfo.optimizationEnabled }}
                        </b>
                      </span>
                    </div>
                    <hr />
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="text-size-small">Other Settings: </span>
                        <span
                          class="text-size-small"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                          style="font-size: 1rem; margin-left: 0.3rem"
                          ><b>
                            {{ tokenInfo.otherSettings }}
                          </b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  code source  -->
              <div
                class="
                  d-md-flex
                  justify-content-between
                  align-items-center
                  bg-white-content
                  py-2
                  col-md-12
                "
                style="margin-top: 1rem"
              >
                <h4 class="card-header-title">
                  <i class="far fa-file-code text-secondary mr-1"> </i>Contract
                  Source Code
                  <span class="font-weight-normal text-secondary"
                    >(Solidity
                    <a
                      href="https://solidity.readthedocs.io/en/v0.5.8/using-the-compiler.html#compiler-input-and-output-json-description"
                      data-toggle="tooltip"
                      title=""
                      target="blank"
                      class="text-primary"
                      data-original-title="Click to learn more"
                      >Standard Json-Input</a
                    >
                    format)
                  </span>
                </h4>
              </div>
              <template v-if="tokenInfo.codeSourceMap">
                <div
                  style="padding: 0.75rem"
                  v-for="(value, key, index) in tokenInfo.codeSourceMap"
                  :key="key"
                >
                  <span
                    ><strong> File {{ index + 1 }}: [{{ key }}]</strong></span
                  >
                  <pre
                    class="js-sourcecopyarea editor ace_editor ace-dawn"
                    style="
                      margin-top: 5px;
                      height: 210px;
                      direction: ltr;
                      text-align: left;
                    "
                    >{{ value }}</pre
                  >
                </div>
              </template>

              <template v-else>
                <div style="padding: 0.75rem">
                  <pre
                    class="js-sourcecopyarea editor ace_editor ace-dawn"
                    style="
                      margin-top: 5px;
                      height: 210px;
                      direction: ltr;
                      text-align: left;
                    "
                    >{{ tokenInfo.codeSource }}</pre
                  >
                </div>
              </template>
              <div
                class="
                  d-md-flex
                  justify-content-between
                  align-items-center
                  bg-white-content
                  py-2
                  col-md-12
                "
              >
                <h4 class="card-header-title">
                  <i class="fa fa-tasks text-secondary mr-1"></i>Contract ABI
                </h4>
              </div>

              <div style="padding: 0.75rem">
                <pre
                  class="js-sourcecopyarea editor ace_editor ace-dawn wordwrap"
                  style="
                    margin-top: 5px;
                    height: 210px;
                    direction: ltr;
                    text-align: left;
                  "
                  >{{ tokenInfo.abi }}
              </pre
                >
              </div>

              <div
                class="
                  d-md-flex
                  justify-content-between
                  align-items-center
                  bg-white-content
                  py-2
                  col-md-12
                "
              >
                <h4 class="card-header-title">
                  <i class="fa fa-code text-secondary mr-1"></i>Contract
                  Creation Code
                </h4>
              </div>

              <div style="padding: 0.75rem">
                <pre
                  class="js-sourcecopyarea editor ace_editor ace-dawn wordwrap"
                  style="
                    margin-top: 5px;
                    height: 210px;
                    direction: ltr;
                    text-align: left;
                  "
                  >{{ tokenInfo.code }}
              </pre
                >
              </div>
            </div>

            <!-- read contract -->
            <div
              style="margin-top: 1rem"
              :style="{
                display: contract == 'Read Contract' ? 'block' : 'none',
              }"
            >
              <div class="d-flex justify-content-between mb-3">
                <p class="ml-1 mr-3 mb-1">
                  <i class="far fa-file-alt text-secondary mr-1"></i> Read
                  Contract Information
                </p>
              </div>
              <template v-for="(item, index) in readAbis" :key="index">
                <div
                  style="
                    margin-top: 1rem;
                    background-color: #fff;
                    border-radius: 0.5rem;
                    border: 1px solid #e7eaf3;
                  "
                >
                  <span :id="'read' + index">
                    <a
                      class="d-block collapsed"
                      :href="['#read' + index + 'Library']"
                      data-toggle="collapse"
                      role="button"
                      style="padding: 0.75rem; background-color: #f8f9fa"
                      aria-expanded="false"
                      :aria-controls="['read' + index + 'Library']"
                    >
                      <span style="color: #606266 !important">
                        {{ item.name }}
                      </span>
                      <span
                        style="float: right"
                        class="card-btn-arrow card-btn-arrow--up ml-2"
                      >
                        <span class="fas fa-arrow-up small"></span>
                      </span>
                    </a>
                  </span>

                  <div :id="'read' + index + 'Library'" class="collapse">
                    <div style="background: rgb(231, 234, 243); height: 1px" />
                    <div class="card-body">
                      <template v-for="(input, i) in item.inputs" :key="i">
                        <div
                          class="row align-items-center mn-3"
                          style="margin-bottom: 0.5rem; padding: 0.5rem"
                        >
                          {{ input.name ? input.name : input.type }} ({{
                            input.type
                          }})
                          <el-input
                            data-container="body"
                            data-toggle="popover"
                            data-placement="top"
                            data-original-title=""
                            title=""
                            :placeholder="
                              input.name
                                ? input.name
                                : input.type + '(' + input.type + ')'
                            "
                            v-model="input.value"
                            style="display: block; margin-top: 0.2rem"
                          />
                        </div>
                      </template>

                      <el-button
                        v-loading="item.loading"
                        type="info"
                        @click="queryRead(item)"
                        size="small"
                        >Query
                      </el-button>

                      <template v-if="item.result">
                        <span style="display: block; margin-top: 0.5rem">
                          <b> Result: </b>{{ item.result }}
                        </span>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <!-- write contract -->
            <div
              style="margin-top: 1rem"
              :style="{
                display: contract == 'Write Contract' ? 'block' : 'none',
              }"
            >
              <div class="d-flex justify-content-between mb-3">
                <p class="ml-1 mr-3 mb-1">
                  <i class="far fa-file-alt text-secondary mr-1"></i> Write
                  Contract Information
                </p>
              </div>
              <template v-for="(item, index) in writeAbis" :key="index">
                <div
                  style="
                    margin-top: 1rem;
                    background-color: #fff;
                    border-radius: 0.5rem;
                    border: 1px solid #e7eaf3;
                  "
                >
                  <span :id="'write' + index">
                    <a
                      class="d-block collapsed"
                      :href="['#write' + index + 'Library']"
                      data-toggle="collapse"
                      role="button"
                      style="padding: 0.75rem; background-color: #f8f9fa"
                      aria-expanded="false"
                      :aria-controls="['write' + index + 'Library']"
                    >
                      <span style="color: #606266 !important">
                        {{ item.name }}
                      </span>
                      <span
                        style="float: right"
                        class="card-btn-arrow card-btn-arrow--up ml-2"
                      >
                        <span class="fas fa-arrow-up small"></span>
                      </span>
                    </a>
                  </span>

                  <div :id="'write' + index + 'Library'" class="collapse">
                    <div style="background: rgb(231, 234, 243); height: 1px" />
                    <div class="card-body">
                      <template v-for="(input, i) in item.inputs" :key="i">
                        <div
                          class="row align-items-center mn-3"
                          style="margin-bottom: 0.5rem; padding: 0.5rem"
                        >
                          {{ input.name ? input.name : input.type }} ({{
                            input.type
                          }})
                          <el-input
                            data-container="body"
                            data-toggle="popover"
                            data-placement="top"
                            data-original-title=""
                            title=""
                            :placeholder="
                              input.name
                                ? input.name
                                : input.type + '(' + input.type + ')'
                            "
                            v-model="input.value"
                            style="display: block; margin-top: 0.2rem"
                          />
                        </div>
                      </template>
                      <template v-if="item.stateMutability == 'payable'">
                        <div
                          class="row align-items-center mn-3"
                          style="margin-bottom: 0.5rem; padding: 0.5rem"
                        >
                          value(uint256)
                          <el-input
                            data-container="body"
                            data-toggle="popover"
                            data-placement="top"
                            data-original-title=""
                            title=""
                            placeholder="value(uint256)"
                            v-model="item.value"
                            style="display: block; margin-top: 0.2rem"
                          />
                        </div>
                      </template>

                      <el-button
                        v-loading="item.loading"
                        type="info"
                        @click="write(item)"
                        size="small"
                        >Write
                      </el-button>

                      <template v-if="item.result">
                        <span style="display: block; margin-top: 0.5rem">
                          <b> Result: </b>{{ item.result }}
                        </span>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div>
              <span class="mb-3"
                ><span class="h5 mb-0"
                  ><i class="fa fa-info-circle text-secondary"></i
                ></span>
                Are you the contract creator?
                <a href="#/contract_verify"><b>Verify and Publish</b></a>
                your contract source code today!<br /><br
              /></span>
              <div id="dividcode">
                <pre class="wordwrap" style="height: 15pc">
                  {{ tokenInfo.byteCode }}
                 </pre
                >
              </div>
            </div>
          </template>
        </div>

        <!-- Internal Txns -->
        <div class="card-body" id="card-innernal" style="display: none">
          <div class="tab-content">
            <input
              type="hidden"
              id="hdnErc20"
              value=""
              onchange="setGlobalValue(this)"
            />
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="d-md-flex align-items-center mb-3">
                <div
                  class="
                    d-flex
                    flex-wrap flex-xl-nowrap
                    text-nowrap
                    align-items-center
                    ml-auto
                  "
                ></div>
                <div class="position-relative ml-1"></div>
              </div>
              <div
                class="table-responsive mb-2 mb-md-0"
                v-loading="internalTxns.loading"
              >
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Txn Hash</th>
                      <th scope="col">Block</th>
                      <th scope="col">
                        <a
                          href="javascript:void(0)"
                          @click="internalTxns.isAge = !internalTxns.isAge"
                          >{{ internalTxns.isAge ? "Age" : "Time" }}</a
                        >
                      </th>
                      <th scope="col">Type</th>
                      <th scope="col">From</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">To</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in internalTxns.data" :key="item._id">
                      <td>
                        <i
                          class="fa fa-check-circle"
                          style="margin-end: 0.3rem"
                          :class="{
                            'text-success': item.status,
                            'text-danger': !item.status,
                          }"
                        ></i>
                        <ItemHash :hash="item.hash" />
                      </td>
                      <td>
                        <a
                          href="#"
                          v-on:click.prevent="showBlock(item.blockNumber)"
                          >{{ item.blockNumber }}</a
                        >
                      </td>
                      <td style="" class="showAge">
                        <TimeAge
                          :timestamp="item.timestamp"
                          :isAge="internalTxns.isAge"
                        />
                      </td>
                      <td>
                        {{ item.tracer }}
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentContract"
                          :address="item.from"
                          :info="item.fromInfo"
                        />
                      </td>
                      <td>
                        <span
                          v-if="item.from != item.to"
                          class="
                            btn btn-xs btn-icon btn-soft-success
                            rounded-circle
                          "
                          ><i
                            class="fas fa-long-arrow-alt-right btn-icon__inner"
                          ></i
                        ></span>

                        <span
                          v-else
                          class="
                            u-label u-label--xs u-label--secondary
                            color-strong
                            text-uppercase text-center
                            w-100
                            rounded
                            text-nowrap
                          "
                          >SELF</span
                        >
                      </td>
                      <td>
                        <ItemAddress
                          :currentAddress="currentContract"
                          :address="item.to"
                          :info="item.toInfo"
                        />
                      </td>
                      <td>
                        {{ formatNumber(item.value, 4) + " RCH" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <NoData :count="internalTxns.data.length" />
              </div>
              <Page
                :execPage="execInternalTxsPage"
                :total="internalTxns.count"
                :currentPage="internalTxns.page"
                :pageSize="internalTxns.pageNum"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ces -->
    <el-dialog
      width="420px"
      v-model="dialogActionShow"
      title="Modify Address Info"
      :show-close="false"
    >
      <el-form :model="actionFrom" label-position="left">
        <el-form-item label="Address:">
          <el-input v-model="actionFrom.address" disabled />
        </el-form-item>
        <el-form-item label="Memo:">
          <el-input
            v-model="actionFrom.memo"
            placeholder="Input memo"
            clearable
            show-word-limit
            maxlength="28"
          />
        </el-form-item>

        <el-form-item label="Icon:" class="title">
          <div class="upload-border">
            <el-upload
              class="avatar-uploader"
              action="/"
              accept="image/jpg, image/jpeg, image/png, image/gif"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="selectFile"
            >
              <img
                v-if="uploadPic"
                :src="uploadPic"
                class="avatar"
                style="border-radius: 50%"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="Official Site:">
          <el-input
            v-model="actionFrom.officialSite"
            placeholder="Input Official Site"
            clearable
          />
        </el-form-item>
        <el-form-item label="Social Profiles:">
          <el-tag
            v-for="tag in actionFrom.socialProfiles"
            :key="tag"
            closable
            :disable-transitions="false"
            style="
              margin-right: 10px;
              margin-top: 10px;
              maxwidth: 100px;
              margin-left: 0px;
            "
            @close="handleClose(tag)"
          >
            {{ parseLongText(tag, 20) }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="saveTagInput"
            v-model="inputValue"
            class="input-new-tag"
            size="small"
            style="
              margin-right: 10px;
              margin-top: 10px;
              maxwidth: 100px;
              margin-left: 0px;
            "
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
          />
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            style="
              margin-right: 10px;
              margin-top: 10px;
              maxwidth: 100px;
              margin-left: 0px;
            "
            >+ Add</el-button
          >
        </el-form-item>
      </el-form>
      <!--  -->
      <div style="text-align: right">
        <el-button type="danger" size="mini" @click="dialogActionShow = false"
          >Cancel</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="confirmAction"
          v-loading="actionLoading"
          >Upload</el-button
        >
      </div>
    </el-dialog>

    <!-- vueCropper -->
    <el-dialog title="Pictrure Crop" v-model="dialogVisible">
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <VuePictureCropper
            :boxStyle="{
              width: '100%',
              height: '100%',
              backgroundColor: '#f8f8f8',
              margin: 'auto',
            }"
            :img="option.img"
            :options="{
              viewMode: 1,
              dragMode: 'crop',
              aspectRatio: 1 / 1,
            }"
          />
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button
            type="primary"
            :loading="uploadLoading"
            @click="onCropResult"
            >Cropper</el-button
          >
        </div>
      </template>
    </el-dialog>
  </main>
  <div id="push"></div>
</template>
<script>
import { getName } from "../../public/assets/js/lang.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import {
  PostNet,
  PostNetUrl,
  PostEthNetUrl,
} from "../../public/assets/js/myRequest.js";
import route from "../router/index";
import {
  getUrlKey,
  parseLongText,
  importForClipboard,
} from "../assets/js/utils.js";

import NCWeb3 from "../assets/js/web3.js";
import { ElMessage, ElMessageBox } from "element-plus";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import ItemAddress from "./components/itemAddress.vue";
import TimeAge from "./components/timeAge.vue";
import Page from "./components/page.vue";
import ItemToken from "./components/item_token.vue";
import ItemHash from "./components/item_hash.vue";
import NoData from "./components/noData.vue";
export default {
  name: "TokenDetail",
  components: {
    VuePictureCropper,
    ItemAddress,
    TimeAge,
    Page,
    ItemToken,
    ItemHash,
    NoData,
  },
  data() {
    return {
      infoLoading: false,
      tokenInfo: { ERC: 0 },
      tokenBalance: [],
      currentPage: 1,
      pageNum: 20,
      total: 0,
      isTransacAge: true,
      TransactionList: [],
      totalSupply: 0,
      currentContract: "",
      HolderList: [],
      currentPageHolder: 1,
      totalHolder: 0,
      transaction: false,
      holders: false,
      contract: "Code",
      readAbis: [],
      writeAbis: [],
      txns: {
        loading: false,
        count: 0,
        page: 1,
        pageNum: 20,
        data: [],
        isAge: true,
      },
      seaech: { type: "", value: "" },
      erc721TokenIds: {
        loading: false,
        count: 0,
        page: 1,
        pageNum: 20,
        data: [],
      },
      // dialog ==========
      dialogActionShow: false,
      actionFrom: {
        address: "",
        memo: "",
        logo: "",
        officialSite: "",
        socialProfiles: [],
      }, // 选中需要修改的数据
      uploadPic: "",
      dialogVisible: false,
      option: {
        outputType: "png",
        img: "", //
      },
      uploadLoading: false,
      uploadFile: null,
      actionLoading: false,
      inputValue: "",
      inputVisible: false,
      // dialog ==========
      select: "",
      internalTxns: {
        loading: false,
        count: 0,
        page: 1,
        pageNum: 20,
        data: [],
        isAge: true,
      },
    };
  },
  mounted() {
    this.infoLoading = true;
    this.currentContract = getUrlKey("c");
    PostNet.PostWith(
      PostNetUrl.tokenInfo,
      (info) => {
        this.infoLoading = false;
        this.tokenInfo = info.data;
        if (this.tokenInfo.ERC == 0) {
          this.execTxsPage(-2);
        } else {
          this.execTransfersPage(-2);
        }
        this.$nextTick(function () {
          importForClipboard();
          if (this.tokenInfo.ERC == 0) {
            $("#transTab").addClass("show active");
            $("#card-body-transaction").attr("style", "display:block");
          } else {
            $("#transactions").addClass("show active");
            $("#card-body").attr("style", "display:block");
          }
        });

        this.readAbis = [];
        this.writeAbis = [];
        if (!info.data.abi) {
          return;
        }
        // readAbis
        var abis = JSON.parse(info.data.abi);
        for (var i = 0; i < abis.length; i++) {
          if (abis[i].type == "function") {
            if (abis[i].stateMutability == "view") {
              this.readAbis.push(abis[i]);
              // } else if (abis[i].stateMutability == "nonpayable") {
            } else {
              this.writeAbis.push(abis[i]);
            }
          }
        }
      },
      (err) => {
        this.infoLoading = false;
      },
      { contract: this.currentContract }
    );

    PostNet.Post2(
      PostEthNetUrl.getTokenBalance,
      (info) => {
        this.tokenBalance = info.data;
        var hasToken = this.tokenBalance.length > 0;
        if (hasToken) {
          $("#token-balance").attr("style", "display:block");
        } else {
          $("#token-balance").attr("style", "display:none");
        }
      },
      { addr: this.currentContract }
    );

    (function () {
      var a = document.getElementById("a-balance");
      var list = document.getElementById("token_balance_a"); // 显示隐藏
      var timer = null;
      list.onmouseover = a.onmouseover = function () {
        if (timer) clearTimeout(timer);
        list.style.display = "block";
      };
      list.onmouseout = a.onmouseout = function () {
        timer = setTimeout(function () {
          list.style.display = "none";
        }, 400);
      };
    })();
  },

  methods: {
    openAccountSelect(accounts, back) {
      this.select = accounts[0];
      var html = ``;
      for (var i = 0; i < accounts.length; i++) {
        var address = accounts[i];
        var ad = address;
        html += ` <div style="
                          padding-bottom: 0.5rem;
                          padding-start: 0.5rem;
                          padding-end: 0.5rem;
                        "
                      >
                        <el-radio
                          v-model="select"
                          :label="${address}"
                          border
                          @change="onSelectChange"
                          style="width: 100% !important"
                          >${ad}</el-radio
                        >
                      </div>`;
      }

      html =
        `<el-scrollbar style=" width: 100%;height: 300px;">` +
        html +
        `</el-scrollbar>`;

      ElMessageBox.confirm(html, "Select Account", {
        dangerouslyUseHTMLString: true,
        confirmButtonText: "Comfirm",
        type: "infp",
        showCancelButton: false,
        center: true,
        showClose: false,
      })
        .then(() => {
          back(this.select);
        })
        .catch(() => {
          back(this.select);
        });
    },
    getName(str1, str2) {
      return getName(str1, str2);
    },
    parseLongText(str, start = 10, end = 6) {
      return parseLongText(str, start, end);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    execHolderPage: function (page) {
      if (page === -2) {
        this.currentPageHolde = 1;
      } else {
        this.currentPageHolder =
          parseInt(this.currentPageHolder) + parseInt(page);
        if (isNaN(this.currentPageHolder)) {
          this.currentPageHolder = 1;
        }
        if (this.currentPageHolder <= 0) {
          this.currentPageHolder = 1;
          return;
        }
        let totalPage = parseInt(this.totalHolder / this.pageNum) + 1;
        if (this.currentPageHolder > totalPage) {
          this.currentPageHolder = totalPage;
          return;
        }
      }
      this.holders = true;
      PostNet.PostWith(
        PostNetUrl.tokenHolderList,
        (info) => {
          this.holders = false;
          this.HolderList = info.data.data || [];
          this.totalHolder = info.data.count;
        },
        (err) => {
          this.holders = false;
        },
        {
          contract: this.currentContract,
          page: this.currentPageHolder,
          pageNum: this.pageNum,
        }
      );
    },
    execTxsPage: function (page) {
      if (page === -2) {
        this.txns.page = 1;
      } else {
        this.txns.page = parseInt(this.txns.page) + parseInt(page);
        if (isNaN(this.txns.page)) {
          this.txns.page = 1;
        }
        if (this.txns.page <= 0) {
          this.txns.page = 1;
          return;
        }
        let totalPage = parseInt(this.txns.count / this.txns.pageNum) + 1;
        if (this.txns.page > totalPage) {
          this.txns.page = totalPage;
          return;
        }
      }

      this.txns.loading = true;
      PostNet.PostWith(
        PostNetUrl.getContractTxns,
        (info) => {
          this.txns.loading = false;
          this.txns.data = info.data.data;
          this.txns.count = info.data.count;
        },
        (err) => {
          this.txns.loading = false;
        },
        {
          contract: this.currentContract,
          page: this.txns.page,
          pageNum: this.txns.pageNum,
        }
      );
    },
    // transfers
    execTransfersPage: function (page) {
      if (page === -2) {
        this.currentPage = 1;
      } else {
        this.currentPage = parseInt(this.currentPage) + parseInt(page);
        if (isNaN(this.currentPage)) {
          this.currentPage = 1;
        }
        if (this.currentPage <= 0) {
          this.currentPage = 1;
          return;
        }
        let totalPage = parseInt(this.total / this.pageNum) + 1;
        if (this.currentPage > totalPage) {
          this.currentPage = totalPage;
          return;
        }
      }
      this.transaction = true;
      PostNet.PostWith(
        PostNetUrl.getTokenTransactionList,
        (info) => {
          this.transaction = false;
          if (info.data && info.data.data) {
            this.TransactionList = info.data.data;
          }
          this.total = info.data.count;
        },
        (err) => {
          this.transaction = false;
        },
        {
          contract: this.currentContract,
          page: this.currentPage,
          pageNum: this.pageNum,
        }
      );
    },
    // transfers
    execERC721TokenIds: function (page) {
      if (page === -2) {
        this.erc721TokenIds.page = 1;
      } else {
        this.erc721TokenIds.page =
          parseInt(this.erc721TokenIds.page) + parseInt(page);
        if (isNaN(this.erc721TokenIds.page)) {
          this.erc721TokenIds.page = 1;
        }
        if (this.erc721TokenIds.page <= 0) {
          this.erc721TokenIds.page = 1;
          return;
        }
        let totalPage =
          parseInt(this.erc721TokenIds.count / this.erc721TokenIds.pageNum) + 1;
        if (this.erc721TokenIds.page > totalPage) {
          this.erc721TokenIds.page = totalPage;
          return;
        }
      }
      this.erc721TokenIds.loading = true;

      PostNet.PostWith(
        PostNetUrl.erc721TokenIds,
        (info) => {
          this.erc721TokenIds.loading = false;
          this.erc721TokenIds.data = info.data.data;
          this.erc721TokenIds.count = info.data.count;
        },
        (err) => {
          this.erc721TokenIds.loading = false;
        },
        {
          contract: this.currentContract,
          page: this.erc721TokenIds.page,
          pageSize: this.erc721TokenIds.pageNum,
        }
      );
    },
    getLinkUrl(str) {
      if (typeof str !== "string") {
        return "#";
      }
      if (!str.startsWith("http")) {
        return "http://" + str;
      }
      return str;
    },

    execInternalTxsPage: function (page) {
      if (page === -2) {
        this.internalTxns.page = 1;
      } else {
        this.internalTxns.page =
          parseInt(this.internalTxns.page) + parseInt(page);
        if (isNaN(this.internalTxns.page)) {
          this.internalTxns.page = 1;
        }
        if (this.internalTxns.page <= 0) {
          this.internalTxns.page = 1;
          return;
        }
        let totalPage =
          parseInt(this.internalTxns.count / this.internalTxns.pageNum) + 1;
        if (this.internalTxns.page > totalPage) {
          this.internalTxns.page = totalPage;
          return;
        }
      }

      this.internalTxns.loading = true;
      PostNet.PostWith(
        PostNetUrl.coinInners,
        (info) => {
          this.internalTxns.loading = false;
          this.internalTxns.data = info.data.data;
          this.internalTxns.count = info.data.count;
        },
        (err) => {
          this.internalTxns.loading = false;
        },
        {
          address: this.currentContract,
          page: this.internalTxns.page,
          pagesize: this.internalTxns.pageNum,
        }
      );
    },

    changeTab(index) {
      $("#card-body").attr("style", "display:none");
      $("#card-body1").attr("style", "display:none");
      $("#card-body-contract").attr("style", "display:none");
      $("#card-body-transaction").attr("style", "display:none");
      $("#card-body-inventory").attr("style", "display:none");
      $("#card-innernal").attr("style", "display:none");
      switch (index) {
        case 1:
          $("#card-body-transaction").attr("style", "display:block");
          this.execTxsPage(-2);
          break;
        case 2:
          $("#card-body").attr("style", "display:block");
          this.execTransfersPage(-2);
          break;
        case 3:
          $("#card-body1").attr("style", "display:block");
          this.execHolderPage(-2);
          break;
        case 4:
          $("#card-body-contract").attr("style", "display:block");
          break;
        case 5:
          $("#card-body-inventory").attr("style", "display:block");
          this.execERC721TokenIds(-2);
          break;
        case 6:
          $("#card-innernal").attr("style", "display:block");
          this.execInternalTxsPage(-2);
          break;
      }
    },
    toTokenHash(hash) {
      // location.href = "/tx/tokentx.html?h=" + hash;
      route.push({
        path: "/txTokenDetail",
        query: {
          h: hash,
        },
      });
    },
    toHash(hash) {
      // location.href = "/tx/tokentx.html?h=" + hash;
      route.push({
        path: "/txDetail",
        query: {
          h: hash,
        },
      });
    },
    toAddress(address) {
      // location.href = "/address?a=" + address;
      route.push({
        path: "/address",
        query: {
          a: address,
        },
      });
    },
    toContract(address) {
      // location.href = "/address?a=" + address;
      route.push({
        path: "/tokenDetail",
        query: {
          c: address,
        },
      });
    },
    showContract(contract) {
      // location.href = "/token/index.html?c=" + contract;
      route.push({
        path: "/TokenDetail",
        query: {
          c: contract,
        },
      });
    },
    queryRead(input) {
      var params = [];
      input.loading = true;
      for (var i = 0; i < input.inputs.length; i++) {
        if (!("value" in input.inputs[i]) || !input.inputs[i].value) {
          setTimeout(function () {
            input.loading = false;
          }, 500);
          return;
        }
        params.push(input.inputs[i].value);
      }
      NCWeb3.query(
        (result) => {
          console.log(result);
          input.loading = false;
          if (!result) {
            return;
          }
          if (input.outputs.length > 1) {
            for (var i = 0; i < input.outputs.length; i++) {
              var item = input.outputs[i];
              if (item.type == "address" && result.length > i) {
                result[i] = result[i];
              }
            }
          } else if (input.outputs.length == 1) {
            var item = input.outputs[0];
            if (item.type == "address") {
              result = result;
            }
          }
          input.result = result;
        },
        (err) => {
          input.loading = false;
          input.result = err;
        },
        JSON.parse(this.tokenInfo.abi),
        this.currentContract,
        input.name,
        params
      );
    },

    write(input) {
      var params = [];
      input.loading = true;
      // for (var i = 0; i < input.inputs.length; i++) {
      //   if (!("value" in input.inputs[i]) || !input.inputs[i].value) {
      //     setTimeout(function () {
      //       input.loading = false;
      //     }, 500);
      //     return;
      //   }
      //   params.push(input.inputs[i].value);
      // }

      for (var i = 0; i < input.inputs.length; i++) {
        if (!("value" in input.inputs[i]) || !input.inputs[i].value) {
          setTimeout(function () {
            input.loading = false;
          }, 500);
          return;
        }
        // if (input.inputs[i].type == "address") {
        //   if (input.inputs[i].value.startsWith("142857")) {
        //     params.push(toAddress(input.inputs[i].value));
        //     continue;
        //   }
        // }
        if (
          input.inputs[i].type.indexOf("[") != -1 &&
          input.inputs[i].type.indexOf("]") != -1
        ) {
          // if (input.inputs[i].type.indexOf("address[") == 0) {
          //   var addrs = input.inputs[i].value.split(",");
          //   for (var i = 0; i < addrs.length; i++) {
          //     if (addrs[i].startsWith("142857")) {
          //       addrs[i] = toAddress(addrs[i]);
          //     }
          //   }
          //   params.push(addrs);
          //   continue;
          // }
          params.push(input.inputs[i].value.split(","));
          continue;
        }
        params.push(input.inputs[i].value);
      }
      var _this = this;
      NCWeb3.sendContractNew(
        (result) => {
          input.loading = false;
          if (!result) {
            return;
          }
          if (input.outputs.length > 1) {
            for (var i = 0; i < input.outputs.length; i++) {
              var item = input.outputs[i];
              if (item.type == "address" && result.length > i) {
                result[i] = result[i];
              }
            }
          } else if (input.outputs.length == 1) {
            var item = input.outputs[0];
            if (item.type == "address") {
              result = result;
            }
          }
          input.result = result;
        },
        (err) => {
          setTimeout(function () {
            input.loading = false;
          }, 500);
          input.result = err;
        },
        JSON.parse(this.tokenInfo.abi),
        this.currentContract,
        input.name,
        params,
        input.value
      );
    },
    selectNumAccount() {},
    // dialog====
    handleClose(tag) {
      this.actionFrom.socialProfiles.splice(
        this.actionFrom.socialProfiles.indexOf(tag),
        1
      );
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      if (inputValue) {
        this.actionFrom.socialProfiles.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    handleAction() {
      this.dialogActionShow = true;
      this.uploadFile = null;
      this.uploadPic = this.tokenInfo.logo ? this.tokenInfo.logo : "";
      /**
       *    officialSite: "",
        socialProfiles: [],
         this.tokenInfo.officialsite = res.data.officialSite;
        this.tokenInfo.socialprofiles = res.data.socialProfiles;
       */
      this.actionFrom["address"] = this.currentContract;
      this.actionFrom["logo"] = this.tokenInfo.logo ? this.tokenInfo.logo : "";
      this.actionFrom["memo"] = this.tokenInfo.memo ? this.tokenInfo.memo : "";
      this.actionFrom["officialSite"] = this.tokenInfo.officialsite
        ? this.tokenInfo.officialsite
        : "";

      this.actionFrom["socialProfiles"] = this.tokenInfo.socialprofiles
        ? this.tokenInfo.socialprofiles
        : [];
    },

    confirmAction() {
      this.upload();
    },
    selectFile(file, fileList) {
      console.log(file);
      const sufix = file.name.split(".")[1] || "";
      if (!["jpg", "jpeg", "png"].includes(sufix)) {
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("file size should less than 5MB!");
        return false;
      }
      this.fileinfo = file;

      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = () => {
        //
        this.option.img = String(reader.result);
        //
        this.dialogVisible = true;
      };
    },
    //
    onCropResult() {
      cropper.getBlob().then((data) => {
        this.dialogVisible = false;
        this.uploadFile = data;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          //
          this.uploadPic = String(reader.result);
          //
          this.dialogVisible = false;
        };
      });
    },
    upload() {
      this.actionLoading = true;
      NCWeb3.signMessage(
        (err, res, nonce) => {
          if (!err && res) {
            console.dir(res);
            const forms = new FormData();
            if (this.uploadFile) {
              const aTime = new Date().getTime(); //
              const fileName = aTime + "." + this.uploadFile.type.substr(6); //
              const file = new File([this.uploadFile], fileName, {
                type: this.uploadFile.type,
              });
              forms.append("file", file);
            } else {
              forms.append(
                "logo",
                this.actionFrom["logo"] == null ? "" : this.actionFrom["logo"]
              );
            }
            forms.append("address", this.actionFrom["address"]);
            forms.append(
              "memo",
              this.actionFrom["memo"] == null ? "" : this.actionFrom["memo"]
            );
            forms.append("nonce", nonce);
            forms.append("sign", res);

            forms.append(
              "officialSite",
              this.actionFrom["officialSite"] == null
                ? ""
                : this.actionFrom["officialSite"]
            );
            forms.append(
              "socialProfiles",
              this.actionFrom["socialProfiles"].join(";")
            );
            if (this.tokenInfo.symbol) {
              forms.append("symbol", this.tokenInfo.symbol);
            }
            PostNet.PostForm(
              PostNetUrl.updateContractInfo,
              (res) => {
                this.actionLoading = false;
                if (res.code === 20000) {
                  this.dialogActionShow = false;
                  this.uploadFile = null;
                  this.uploadPic = "";
                  if (this.currentContract == res.data.address) {
                    this.tokenInfo.logo = res.data.logo;
                    this.tokenInfo.memo = res.data.memo;
                    this.tokenInfo.officialsite = res.data.officialSite;
                    this.tokenInfo.socialprofiles = res.data.socialProfiles;
                  }
                } else {
                  ElMessage.error(res.message);
                }
              },
              (err) => {
                ElMessage.error(err);
                this.dialogActionShow = false;
                this.actionLoading = false;
              },
              forms
            );
          } else {
            this.actionLoading = true;
            ElMessage.error(err);
          }
        },
        (err) => {
          this.actionLoading = false;
          ElMessage.error(err);
        }
      );
    },

    showBlock: function (blockNum) {
      // location.href = "block?b=" + blockNum;

      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
    },
  },
};
</script>
<style scoped>
.test-tip {
  color: #77838f;
}
@media (min-width: 768px) {
  .hidden {
    display: none;
  }
}
@media (max-width: 768px) {
  .hidden {
    display: block;
  }
}

/* ---dialog */
.upload-border {
  padding: 1px;
  width: 124px;
  height: 124px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.upload-border:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.cropper-content .cropper {
  width: auto;
  height: 300px;
}

#ul_balance li {
  list-style: none;
}

#ul_balance {
  margin: 0;
  padding: 0.5rem 0;
}

#a-balance {
  color: #1e2022;
}

#a-balance:hover {
  color: #3498db;
}

#ul_balance li a {
  color: #1e2022;
}

#ul_balance li a:hover {
  color: #3498db;
}
.sep {
  list-style: none;
  height: 1px;
  background: #e7eaf3;
  overflow: hidden;
  line-height: 1px;
  margin: 0 0.5rem;
  padding: 0;
  font-size: 0px;
}
</style>
